import "./RoundedButton.scss";
import CircularProgress from "@mui/material/CircularProgress";

export const RoundedButton = ({
  label,
  type,
  loading,
  onClick,
}: {
  label: string;
  type: any;
  loading: boolean;
  onClick?: any;
}) => {
  return (
    <button type={type} className="button" onClick={onClick}>
      {loading && <CircularProgress size={20} color="inherit" />}
      {label}
    </button>
  );
};
